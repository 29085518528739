<template>
  <div class="page">
    <ChipsHowToPlay></ChipsHowToPlay>
  </div>
</template>
<script>
import { onBeforeMount, onMounted, ref } from "vue";
import { mapState } from "vuex";
import appBridging from "@/utils/appBridging.js";
import ChipsHowToPlay from "@/components/rules/ChipsHowToPlay"
export default {
  components: {
    ChipsHowToPlay
  },
  data() {
    return {};
  },
};
</script>

<style lang="less" scoped></style>