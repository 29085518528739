<template>
  <div class="play">
    <!-- 返回、标题 -->
    <div class="play-header" :style="{ paddingTop: 35 + statusBarHeight + 'px' }">
      <Back 
        :clickHandle="handleClosePage"
        :innerText="localesPackage?.['yuli_back']?.[localesEnv]"
      />
    </div>
    <div class="play-content">
      <h1 class="play-content-title">
        <img
          :src="
            require('@/assets/images/YuliApp/howToPlay/icon_breed_on.svg')
          "
        />{{ localesPackage?.['yuli_chip_upgrade']?.[localesEnv] }}
      </h1>

      <p class="text play-content-textOne">
        {{ localesPackage?.['yuli_chip_content']?.[localesEnv] }}
      </p>
      <p class="text play-content-textTwo">
        {{ localesPackage?.['yuli_chip_content2']?.[localesEnv] }}
      </p>
      <div class="play-content-formOne">
        <img
          class="play-content-formOne-bg"
          :src="imgFrom1"
        />
        <img
          class="play-content-formOne-large"
          @click="showFormImage('formOne')"
          :src="
            require('@/assets/images/YuliApp/chipsHowToPlay/icon_enlarge.svg')
          "
        />
      </div>
      <p class="text play-content-textThree">
        {{ localesPackage?.['yuli_chip_content3']?.[localesEnv] }}
      </p>
      <div class="play-content-formTwo">
        <img
          class="play-content-formTwo-bg"
          :src="imgFrom2"
        />
        <img
          class="play-content-formTwo-large"
          @click="showFormImage('formTwo')"
          :src="
            require('@/assets/images/YuliApp/chipsHowToPlay/icon_enlarge.svg')
          "
        />
      </div>
      <p class="text play-content-textThree threeColor">
        {{ localesPackage?.['yuli_chip_content4']?.[localesEnv] }}
      </p>
    </div>

    <div class="play-popup" v-if="show_large_formOne">
      <div class="mask" @click="closeFormImage"></div>
      <div
        class="play-popup-formImage"
        :class="`formImage${cur_show_image}`"
        id="formImage"
      >
        <img
          :src="cur_show_image == 1 ? imgFrom1 : imgFrom2"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { onBeforeMount, onMounted, ref, nextTick } from "vue";
import appBridging from "@/utils/appBridging.js";
import Hammer from "hammerjs";
import {getChipHowToPlayLanguage} from "@/api/language.js";
import store from "@/store";
import Back from "@/components/universal/Back";

export default {
  components: {
    Back
  },
  computed: {
    ...mapState({
      init: (state) => state.global.init,
      statusBarHeight: (state) => state.global.statusBarHeight,
      languageEnv: (state) => state.global.languageEnv,
      languagePackage: (state) => state.global.languagePackage,
    }),
  },
  watch: {
    languageEnv: {
      async handler(value) {
        console.log('languageEnv: ', value);
        if(value){
          this.imgInitHandle(value);
          this.localesEnv = value;
        }
      },
      immediate: true,
      deep: true,
    },
    languagePackage: {
      async handler(value) {
        console.log('languagePackage: ', value);
        if(value){
          this.localesPackage = value;
        }
      },
      immediate: true,
      deep: true,
    }
  },
  setup() {
    /**语言包 - ref */
    const localesPackage = ref({});
    /**语言环境 - ref */
    const localesEnv = ref('en');
    const show_large_formOne = ref(false);
    const cur_show_image = ref(1); //1：表格1, 2:表格2
    
    const imgFrom1 = ref(require(`@/assets/images/YuliApp/chipsHowToPlay/chip/img_form_1@2x_en.png`));
    const imgFrom2 = ref(require(`@/assets/images/YuliApp/chipsHowToPlay/chip/img_form_2@2x_en.png`));

    let config = {};
    let id = null;
    let mc = null;
    let timer = false;
    let translateX = 0;
    let translateY = 0;
    let scale = 1;
    let firstTouch = true;
    let relateX = 0;
    let relateY = 0;
    let oldX = 0;
    let oldY = 0;
    let oldScale = 1;

    onBeforeMount(async ()=>{
      appBridging.getSettingLaungage();
      const res = await getChipHowToPlayLanguage();
      store.dispatch("global/setLanguagePackage", res);// 存到stroe
    })

    onMounted(() => {
      appBridging.getStatusBarHeight();
      appBridging.closeLoading();
    });

    const imgInitHandle = (env, defaultEnv = 'en') => {
      try {
        imgFrom1.value = require(`@/assets/images/YuliApp/chipsHowToPlay/chip/img_form_1@2x_${env}.png`);
      } catch (error) {
        imgFrom1.value = require(`@/assets/images/YuliApp/chipsHowToPlay/chip/img_form_1@2x_${defaultEnv}.png`);
      }
      try {
        imgFrom2.value = require(`@/assets/images/YuliApp/chipsHowToPlay/chip/img_form_2@2x_${env}.png`);
      } catch (error) {
        imgFrom2.value = require(`@/assets/images/YuliApp/chipsHowToPlay/chip/img_form_2@2x_${defaultEnv}.png`);
      }
    }

    const picInit = () => {
      id = document.getElementById("formImage");
      mc = new Hammer(id);
      relateX = 0;
      relateY = 0;

      mc.add(
        new Hammer.Pan({
          direction: Hammer.DIRECTION_ALL,
          threshold: 0,
          pointers: 0,
        })
      );
      mc.add(
        new Hammer.Pinch({
          threshold: 0,
        })
      ).recognizeWith(mc.get("pan"));

      mc.on("hammer.input", isFinal);
      mc.on("panstart panmove", onPan);
      mc.on("pinchstart pinchmove", onPinch);

      setPosition();
    };
    const isFinal = (ev) => {
      if (ev.isFinal) {
        oldX = translateX;
        oldY = translateY;
        oldScale = scale;
      }
    };
    // 初始化图片位置及缩放
    const setPosition = () => {
      selfPosition({
        translateX: relateX,
        translateY: relateY,
        scale: 1,
      });
    };
    // 单点触发 - 拖拉
    const onPan = (ev) => {
      // console.log( firstTouch)
      if (firstTouch) {
        oldX = relateX;
        oldY = relateY;
      }
      // console.log( oldX)
      // console.log( oldY)
      translateX = oldX + ev.deltaX;
      translateY = oldY + ev.deltaY;
      const position = {
        translateX: translateX,
        translateY: translateY,
        scale: scale,
      };
      selfPosition(position);
      firstTouch = false;
    };
    // 多点触发 - 缩放
    const onPinch = (ev) => {
      scale = oldScale * ev.scale;
      selfPosition({
        translateX: translateX,
        translateY: translateY,
        scale: scale,
      });
      //  selfPosition( position)
    };
    const selfPosition = (pos) => {
      return picAnimate()(() => tempPos(pos));
    };
    const tempPos = (pos) => {
      let style = [
        `translate3d(${pos.translateX}px, ${pos.translateY}px, 0)`,
        `scale(${pos.scale}, ${pos.scale})`,
        // `scale(${pos.scale > 1.2 ? 1.2 : pos.scale}, ${pos.scale > 1.2 ? 1.2 : pos.scale})`
      ];
      style = style.join(" ");
      id.style.transform = style;
    };
    const picAnimate = () => {
      return (
        window[Hammer.prefixed(window, "requestAnimationFrame")] ||
        function (callback) {
          setTimeout(callback, 1000 / 60);
        }
      );
    };

    /**
     * 展示图片
     */
    const showFormImage = (type) => {
      if (type === "formOne") {
        cur_show_image.value = 1;
      } else {
        cur_show_image.value = 2;
      }
      show_large_formOne.value = true;
      nextTick(() => {
        picInit();
      });
    };

    /**
     * 关闭图片
     */
    const closeFormImage = () => {
      show_large_formOne.value = false;
    };
    /**
     * 关闭页面
     */
    const handleClosePage = () => {
      appBridging.closePage();
    };
    return {
      handleClosePage,
      show_large_formOne,
      showFormImage,
      closeFormImage,
      cur_show_image,
      localesPackage,
      localesEnv,
      imgInitHandle,
      imgFrom1,
      imgFrom2
    };
  },
};
</script>
<style lang="less" scoped>
h1,
p {
  padding: 0;
  margin: 0;
}
.play {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  overflow: hidden;
  // background: linear-gradient(180deg, #352d62ff 0%, #2d64a5ff 100%);
  background-image: url("~@/assets/images/YuliApp/howToPlay/page_bg.png");
  background-size: 100% 100%;
  &-header {
    position: relative;
    width: 100%;
    height: 30px;
    // background-image: url("~@/assets/images/YuliApp/howToPlay/header_bg.png");
    // background-size: 100% 100%;

    &-block {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 100%;
      height: 30px;
      &-title {
        position: absolute;
        // left: 50%;
        top: 0;
        // transform: translate(-50%, 0);
        right: 15px;
        height: 30px;
        color: rgba(255, 255, 255, 1);
        font-size: 24px;
        font-weight: 500;
        font-family: "PingFang SC";
        text-align: right;
        line-height: 28.8px;
      }
    }
  }
  &-content {
    box-sizing: border-box;
    width: 100%;
    padding: 0 14.82px 0 16.59px;
    margin-top: 18px;
    flex-grow: 1;
    overflow: auto;
    padding-bottom: 20px;
    &-title {
      height: 26px;
      color: #FFFFFF;
      font-size: 16px;
      font-weight: 500;
      font-family: "PingFang HK";
      text-align: left;
      line-height: 26px;

      img {
        width: 12px;
        height: 12px;
        margin-right: 4px;
        vertical-align: middle;
      }
    }
    .text {
      padding: 0 5.59px;
      text-indent: 2em;
      color: rgba(255, 255, 255, 0.75);
      font-size: 14px;
      font-weight: 400;
      font-family: "PingFang HK";
      text-align: left;
      line-height: 22.4px;
    }
    &-textTwo {
      margin-bottom: 10px;
    }
    &-formOne {
      position: relative;
      width: 344.18px;
      height: 195.07px;
      margin-bottom: 10px;
      // background-image: url("~@/assets/images/YuliApp/chipsHowToPlay/chip/img_form_1@2x.png");
      // background-size: 100% 100%;
      &-bg {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 0;
        top: 0;
        left: 0;
      }
      &-large {
        width: 24px;
        height: 24px;
        position: absolute;
        bottom: 12.7px;
        right: 10.18px;
      }
    }
    &-textThree {
      margin-bottom: 10px;
    }

    &-formTwo {
      position: relative;
      width: 344.18px;
      height: 328.27px;
      margin-bottom: 10px;
      // background-image: url("~@/assets/images/YuliApp/chipsHowToPlay/chip/img_form_2@2x.png");
      // background-size: 100% 100%;
      &-bg {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 0;
        top: 0;
        left: 0;
      }
      &-large {
        width: 24px;
        height: 24px;
        position: absolute;
        bottom: 12.7px;
        right: 10.18px;
      }
    }
  }
  //隐藏滚动条
  .play-content::-webkit-scrollbar {
    display: none;
  }
  .play-content {
    scrollbar-width: none;
    -ms-overflow-style: none;
    overflow-x: hidden;
    overflow-y: auto;
  }

  &-popup {
    width: 100%;
    height: 100%;
    margin-top: -60px;
    overflow: hidden;
    position: absolute;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 999;
    .mask {
      position: absolute;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.9);
      top: 0;
      left: 0;
    }
    &-formImage {
      position: absolute;
      z-index: 1000;
      top: 223px;
      display: flex;
      justify-content: center;
      img {
        position: absolute;
        width: 100%;
        height: 100%;
      }
    }
    .formImage1 {
      width: 344.18px;
      height: 195.07px;
      background: #2D2D54;
      border-radius: 10px;
    }
    .formImage2 {
      width: 344.18px;
      height: 328.27px;
      background: #2D2D54;
      border-radius: 10px;
    }
  }
}
</style>
